import React, { useState, useMemo, useRef, useReducer, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import Color from 'color';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, filterDark } from '../utils/typography';
import kMapLayout from '../posts/map.yaml';

const kBackgroundFull = 1500;
const kBackgroundCenter = 900;
const kBackgroundSvgHeight = 750;

const kMapLine = 8;
const kMapLineHotspot = 64;
const kMapLineEndCap = 16;
const kMapPadding = 16;
const kMapLabelWidth = 65;
const kMapLabelHeight = 25;
const kMapCalloutWidth = 175;
const kMapCalloutHeight = 200;
const kMapCalloutArrow = 10;
const kMapCalloutThumbnail = 100;
const kMapHoverDist = 150;
const kMapLowlight = 0.8; // 0 = no fade, 1 = completely faded
const kMapTransition = (prop = 'all') => `${prop} 0.15s ease`;

const MapWrapper = styled.div({
  gridColumn: 'screen-start / screen-end',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative', // So svg can be anchored.
  overflow: 'hidden',
});

const MapImage = styled.div(({ theme }) => ({
  filter: theme.dark && 'invert(80%)', // Different from main filter.
  width: kBackgroundFull,
  marginLeft: `-${((kBackgroundFull - kBackgroundCenter) / kBackgroundCenter / 2) * 100}%`,
  marginRight: `-${((kBackgroundFull - kBackgroundCenter) / kBackgroundCenter / 2) * 100}%`,
}));

const MapSvg = styled.svg({
  position: 'absolute',
  top: 0,
  width: `min(${kBackgroundCenter}px, 100vw)`,
});

const MapAnnotations = styled.div(({ width }) => ({
  position: 'absolute',
  top: 0,
  width: kBackgroundCenter,
  height: kBackgroundSvgHeight,
  transformOrigin: 'top',
  transform: width < kBackgroundCenter && `scale(${width / kBackgroundCenter})`,
  pointerEvents: 'none',
}));

const PostLabel = styled.div(
  ({ theme, cx, cy, color, dir, isLowlight = false, isVisible = true }) => ({
    // Create space for padding.
    position: 'absolute',
    left: cx + ({ e: kMapPadding, w: -kMapPadding - kMapLabelWidth }[dir] || -kMapLabelWidth / 2),
    top: cy + ({ n: -kMapPadding - kMapLabelHeight, s: kMapPadding }[dir] || -kMapLabelHeight / 2),
    width: kMapLabelWidth,
    height: kMapLabelHeight,

    // Flex parent properties.
    display: 'flex',
    flexDirection: 'row',
    textAlign: { e: 'start', w: 'end' }[dir] || 'center',
    justifyContent: { e: 'flex-start', w: 'flex-end' }[dir] || 'center',
    alignItems: { n: 'flex-end', s: 'flex-start' }[dir] || 'center',

    // User interaction and fade animation.
    userSelect: 'none',
    cursor: 'pointer',
    opacity: isVisible ? 1 : 0,
    transition: kMapTransition('opacity,color'),

    // Self and child text appearance.
    WebkitTextStroke: !color && `${theme.background} 6px`,
    color:
      color && isLowlight
        ? Color(color).mix(Color(theme.background), kMapLowlight).string()
        : color,
    fontSize: '0.60rem',
    fontWeight: 'bold',
    lineHeight: 1.1,
  }),
);

const PostCallout = styled.div(({ theme, cx, cy, color, dir, isVisible = false }) => ({
  // Create space for padding and arrow.
  position: 'absolute',
  left:
    cx +
    ({
      e: kMapPadding + kMapCalloutArrow,
      w: -kMapPadding - kMapCalloutArrow - kMapCalloutWidth,
    }[dir] || -kMapCalloutWidth / 2),
  top:
    cy +
    ({
      n: -kMapPadding - kMapCalloutArrow - kMapCalloutHeight,
      s: kMapPadding + kMapCalloutArrow,
    }[dir] || -kMapCalloutHeight / 2),
  width: kMapCalloutWidth,
  height: kMapCalloutHeight,
  zIndex: 1, // Order above other elements (especially labels).

  // Flex parent properties.
  display: 'flex',
  flexDirection: 'column',
  justifyContent: dir === 'n' ? 'flex-end' : dir === 's' ? 'flex-start' : 'center',
  alignItems: 'stretch',

  // User interaction and pop-out animation.
  userSelect: 'none',
  cursor: 'pointer',
  pointerEvents: isVisible ? 'auto' : 'none',
  opacity: isVisible ? 1 : 0,
  transformOrigin: { n: 'bottom', s: 'top', e: 'left', w: 'right' }[dir],
  transform: !isVisible && 'scale(0.85)',
  transition: kMapTransition('all'),

  // Arrow on side using borders.
  '&:before': {
    content: '""',
    width: 0,
    height: 0,
    border: `${kMapCalloutArrow}px solid transparent`,
    position: 'absolute',
    ...{
      n: {
        borderTop: `${kMapCalloutArrow}px solid ${color}`,
        bottom: -2 * kMapCalloutArrow,
        left: `calc(50% - ${kMapCalloutArrow}px)`,
      },
      s: {
        borderBottom: `${kMapCalloutArrow}px solid ${color}`,
        top: -2 * kMapCalloutArrow,
        left: `calc(50% - ${kMapCalloutArrow}px)`,
      },
      e: {
        borderRight: `${kMapCalloutArrow}px solid ${color}`,
        left: -2 * kMapCalloutArrow,
        top: `calc(50% - ${kMapCalloutArrow}px)`,
      },
      w: {
        borderLeft: `${kMapCalloutArrow}px solid ${color}`,
        right: -2 * kMapCalloutArrow,
        top: `calc(50% - ${kMapCalloutArrow}px)`,
      },
    }[dir],
  },
}));

const PostCalloutInner = styled.div(({ theme, color }) => ({
  backgroundColor: theme.background,
  border: `1px solid ${color}`,
  borderRadius: 4,
  overflow: 'hidden',
}));

const Splash = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: theme.foreground,
}));

const Thumbnail = styled.figure(({ theme }) => ({
  flexShrink: 0,
  width: kMapCalloutThumbnail,
  marginBottom: 0,
  '& img': {
    // Filter colors for dark mode.
    filter: theme.dark && filterDark,
  },
}));

const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  overflow: 'auto',
  padding: 8,
}));

const Title = styled.div(({ theme }) => ({
  fontSize: '0.85rem',
  fontWeight: 'bold',
  color: theme.gray.darker,
  marginBottom: rhythm(0.15),
}));

const Subtitle = styled.div(({ theme }) => ({
  fontSize: '0.65rem',
  color: theme.gray.dark,
  marginBottom: rhythm(0.15),
}));

const Byline = styled.div(({ theme }) => ({
  fontSize: '0.55rem',
  color: theme.gray.light,
}));

function Path({
  points,
  color,
  isHighlight,
  isLowlight,
  onMouseEnter,
  onMouseLeave,
  pointerEvents,
}) {
  const theme = useTheme();
  const d = `M ${points.map(([x, y]) => `${x} ${y}`).join(' L ')}`;
  // Draw hover hotspot path behind visible path.
  return (
    <g>
      <path
        d={d}
        fill='none'
        stroke='transparent'
        strokeWidth={kMapLineHotspot}
        strokeLinecap='round'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ pointerEvents }}
      ></path>
      <path
        d={d}
        fill='none'
        strokeWidth={isHighlight ? kMapLine + 1.5 : kMapLine}
        strokeLinecap='round'
        strokeLinejoin='round'
        style={{
          stroke: isLowlight
            ? Color(color).mix(Color(theme.background), kMapLowlight).string()
            : color,
          transition: kMapTransition('all'),
          pointerEvents: 'none',
        }}
      ></path>
    </g>
  );
}

function EndCapPath({ first, second, ...rest }) {
  const ortho = [second[1] - first[1], first[0] - second[0]];
  const norm = kMapLineEndCap / Math.sqrt(ortho[0] ** 2 + ortho[1] ** 2) / 2;
  ortho[0] *= norm;
  ortho[1] *= norm;
  return (
    <Path
      points={[
        [first[0] - ortho[0], first[1] - ortho[1]],
        [first[0] + ortho[0], first[1] + ortho[1]],
      ]}
      {...rest}
    />
  );
}

function LineLabel({ x, y, label, color, isHighlight, isLowlight }) {
  const theme = useTheme();
  return (
    <>
      <text
        x={x}
        y={y}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{
          pointerEvents: 'none',
          transition: kMapTransition('font-size'),
          fontSize: isHighlight ? '1.05rem' : '1rem',
          fontWeight: 'bold',
          stroke: theme.background,
          strokeWidth: 10,
        }}
      >
        {label}
      </text>
      <text
        x={x}
        y={y}
        textAnchor='middle'
        dominantBaseline='middle'
        style={{
          pointerEvents: 'none',
          transition: kMapTransition('all'),
          fontSize: isHighlight ? '1.05rem' : '1rem',
          fontWeight: 'bold',
          fill: isLowlight
            ? Color(color).mix(Color(theme.background), kMapLowlight).string()
            : color,
        }}
      >
        {label}
      </text>
    </>
  );
}

function getPointAlongPath(path, offset) {
  let dist = 0;
  for (let i = 0; i < path.length - 1; i++) {
    const disti = Math.sqrt(
      (path[i + 1][0] - path[i][0]) ** 2 + (path[i + 1][1] - path[i][1]) ** 2,
    );
    if (offset <= dist + disti) {
      // Point is within the current segment.
      const t = (offset - dist) / disti;
      return [(1 - t) * path[i][0] + t * path[i + 1][0], (1 - t) * path[i][1] + t * path[i + 1][1]];
    }
    dist += disti;
  }
  return path[path.length - 1];
}

export default function BlogIndex({ data, location }) {
  const { siteName } = data.site.siteMetadata;
  const postsData = useMemo(() => new Map(data.allMdx.nodes.map((n) => [n.fields.slug, n])), [
    data,
  ]);
  const svgRef = useRef(null);
  const [{ mouseX, mouseY }, setMouse] = useState({ mouseX: 0, mouseY: 0 });
  const [{ hoveredLine }, setHovered] = useState({});

  // Whenever window resizes, need to recalculate annotation positions/scale based on svgRef.
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    const listener = () => forceUpdate();
    window.addEventListener('resize', listener);
    forceUpdate(); // Update when component first mounts.
    return () => window.removeEventListener('resize', listener);
  }, []);

  // Compute closest post on currently hovered line.
  let hoveredPostIdx = -1;
  if (hoveredLine) {
    const hoveredPath = kMapLayout[hoveredLine].path;
    const hoveredPosts = kMapLayout[hoveredLine].posts;
    if (hoveredPosts.length > 0) {
      const [distSq, minIdx] = hoveredPosts
        .map((p, i) => {
          const [x, y] = getPointAlongPath(hoveredPath, p[1]);
          return [(x - mouseX) ** 2 + (y - mouseY) ** 2, i];
        })
        .reduce((r, a) => (a[0] < r[0] ? a : r));
      if (distSq < kMapHoverDist ** 2) hoveredPostIdx = minIdx;
    }
  }

  // Mouse handler to register current pointer location within svg.
  const handler = (e) => {
    const { x, y, width } = svgRef.current.getBoundingClientRect();
    const scale = kBackgroundCenter / width;
    setMouse({
      mouseX: (e.clientX - x) * scale,
      mouseY: (e.clientY - y) * scale,
    });
  };

  // Must draw in 3 passes for correct z-order: line paths, labels, boxes.
  return (
    <Layout location={location} siteName={siteName}>
      <SEO title='Home' />
      <MapWrapper>
        <MapImage>
          <Img fluid={data.file.childImageSharp.fluid} />
        </MapImage>
        <MapSvg
          ref={svgRef}
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          viewBox={`0 0 ${kBackgroundCenter} ${kBackgroundSvgHeight}`}
          onMouseMoveCapture={handler}
        >
          {Object.entries(kMapLayout).map(([line, { label, color, path, posts }], lidx) => {
            const pathProps = {
              color,
              isHighlight: hoveredLine && hoveredLine === line,
              isLowlight: hoveredLine && hoveredLine !== line,
              onMouseEnter: () => setHovered({ hoveredLine: line }),
              onMouseLeave: () => setHovered({}),
              pointerEvents: hoveredLine && hoveredLine !== line && 'none',
            };
            const labelProps = {
              color,
              isHighlight: hoveredLine && hoveredLine === line,
              isLowlight: hoveredLine && hoveredLine !== line,
            };
            return (
              <g key={lidx} id={`line-${line}`}>
                <Path points={path} {...pathProps} />
                <EndCapPath first={path[0]} second={path[1]} {...pathProps} />
                <EndCapPath
                  first={path[path.length - 1]}
                  second={path[path.length - 2]}
                  {...pathProps}
                />
                <LineLabel label={label[0]} x={label[1]} y={label[2]} {...labelProps} />
                <g key={lidx} id={`stations-${line}`}>
                  {posts.map(([slug, offset, dir], pidx) => {
                    const data = postsData.get(`/${slug}/`);
                    if (!data) {
                      if (process.env.NODE_ENV !== 'production') {
                        // During production, posts can be hidden (e.g. prefixed with '_').
                        // In other modes, the data is actually missing.
                        throw Error(`No data found for post: /${slug}/`);
                      }
                      return null;
                    }
                    const [cx, cy] = getPointAlongPath(path, offset);
                    const isActive = hoveredLine === line && hoveredPostIdx === pidx;
                    return (
                      <circle
                        key={pidx}
                        cx={cx}
                        cy={cy}
                        r={isActive ? 6 : 4.5}
                        fill='white'
                        stroke='black'
                        strokeWidth={isActive ? 3 : 2.5}
                        style={{
                          pointerEvents: 'none',
                          transition: kMapTransition('all'),
                        }}
                      />
                    );
                  })}
                </g>
              </g>
            );
          })}
        </MapSvg>
        <MapAnnotations width={svgRef.current && svgRef.current.getBoundingClientRect().width}>
          {Object.entries(kMapLayout).map(([line, { color, path, posts }], lidx) => {
            return (
              <React.Fragment key={lidx}>
                {posts.map(([slug, offset, dir], pidx) => {
                  const data = postsData.get(`/${slug}/`);
                  if (!data) return null;
                  const [cx, cy] = getPointAlongPath(path, offset);
                  const isActive = hoveredLine === line && hoveredPostIdx === pidx;
                  const isLowlight = hoveredLine && hoveredLine !== line;
                  return (
                    <React.Fragment key={pidx}>
                      <PostLabel
                        id={`label-stroke-${line}-${pidx}`}
                        cx={cx}
                        cy={cy}
                        dir={dir}
                        isVisible={!isActive}
                      >
                        {data.frontmatter.alias}
                      </PostLabel>
                      <PostLabel
                        id={`label-${line}-${pidx}`}
                        cx={cx}
                        cy={cy}
                        dir={dir}
                        color={color}
                        isLowlight={isLowlight}
                        isVisible={!isActive}
                        onMouseEnter={() => setHovered({ hoveredLine: line })}
                        onMouseLeave={() => setHovered({})}
                      >
                        {data.frontmatter.alias}
                      </PostLabel>
                      <PostCallout
                        id={`callout-${line}-${pidx}`}
                        cx={cx}
                        cy={cy}
                        dir={dir}
                        color={color}
                        isVisible={isActive}
                        onMouseEnter={() => setHovered({ hoveredLine: line })}
                        onMouseLeave={() => setHovered({})}
                      >
                        <PostCalloutInner color={color}>
                          <Splash>
                            <Thumbnail>
                              <Link style={{ borderBottom: 'none' }} to={data.fields.slug}>
                                {data.frontmatter.thumbnail && (
                                  <Img fluid={data.frontmatter.thumbnail.childImageSharp.fluid} />
                                )}
                              </Link>
                            </Thumbnail>
                          </Splash>
                          <Content>
                            <Link style={{ borderBottom: 'none' }} to={data.fields.slug}>
                              <Title>{data.frontmatter.title}</Title>
                              {data.frontmatter.subtitle && (
                                <Subtitle>{data.frontmatter.subtitle}</Subtitle>
                              )}
                            </Link>
                            <Byline>
                              {data.frontmatter.published}
                              &nbsp;&nbsp;•&nbsp;&nbsp;
                              {data.fields.readingTime.text}
                            </Byline>
                          </Content>
                        </PostCalloutInner>
                      </PostCallout>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </MapAnnotations>
      </MapWrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
      }
    }
    file(relativePath: { eq: "homepage.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___published], order: DESC }) {
      nodes {
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          title
          subtitle
          alias
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          published(formatString: "DD MMM YYYY")
        }
      }
    }
  }
`;
